import React, { useContext } from "react";

import {
  FormTypeItem,
  FormTypeItemImage,
  FormTypeItemContent,
  FormTypeItemText,
  FormTypeItemTitle,
  ContentInput,
} from "./styled";

import Input from "components/Form/Input";
import Check from "components/Form/Check";
import { CoreContext } from "context/CoreContext";

export default function CardContentType({
  item,
  formValue,
  changeForm,
  preview,
  description,
}) {
  const { setModal } = useContext(CoreContext);

  return (
    <>
      <FormTypeItem
        onClick={preview ? null : () => changeForm(item, "other")}
        preview={preview}
      >
        <FormTypeItemImage image={item?.image} />
        <FormTypeItemContent>
          {preview ? (
            <FormTypeItemTitle>{item?.title}</FormTypeItemTitle>
          ) : (
            <Check
              label={item?.title}
              checked={formValue("other")?.title === item?.title}
            />
          )}
          {!item?.other || preview ? (
            <FormTypeItemText preview={preview}>
              {preview && item?.other ? description : item?.text}
            </FormTypeItemText>
          ) : (
            <ContentInput>
              <Input
                type={"textarea"}
                small
                placeholder="Informe detalhadamente a editoria de conteúdo que deseja"
                value={formValue("other_description")}
                onChange={(e) =>
                  changeForm(e.target.value, "other_description")
                }
              />
            </ContentInput>
          )}
        </FormTypeItemContent>
        <FormTypeItemText
          onClick={() =>
            setModal({
              type: "media",
              item: { isVideo: true, file: { url: item.exampleVideoLink } },
            })
          }
          link
        >
          Ver exemplo
        </FormTypeItemText>
      </FormTypeItem>
    </>
  );
}
